import React from "react"
import { Link } from "gatsby"
import Layout from "../layouts/default"
import Seo from "../components/seo"

const IndexPage = () => (
    <Layout>
        <Seo title="light object film" />
        <nav className="footer">
            <ul>
                <li>
                    <Link to={"/light"}>light</Link>
                </li>
                <li>
                    <Link to={"/object"}>object</Link>
                </li>
                <li>
                    <Link to={"/film"}>film</Link>
                </li>
            </ul>
        </nav>
    </Layout>
)

export default IndexPage
