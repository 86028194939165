import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
    <header className="header">
        <h1>
            <Link to="/">
                {siteTitle}
            </Link>
        </h1>
        <div>
            <a href="mailto:kuno@kuno.ist">Contact</a>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
