import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/header"
import { Helmet } from "react-helmet"

const Layout = ({ children, className, hasHeader = true }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <div className={className ? className : "default"}>
            <Helmet>
                <link rel="stylesheet" href="/layout.css" />
            </Helmet>
            <div className="site">
                {hasHeader && (
                    <Header siteTitle={data.site.siteMetadata.title} />
                )}
                <main>{children}</main>
            </div>
        </div>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
